.Countdown {
  position: absolute;
  bottom: -13px;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: auto;
  margin-right: auto;
}

.Countdown-col {
  display: inline-block;
}

.Countdown-col-element {
  display: inline-block;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #c21718;
  border-radius: 5px;
  width: 100px;
  padding: 5px;
}

.Countdown-col-element strong {
  font-size: 2em;
  color: white;
}

.Countdown-col-element span {
  font-size: 1.2em;
  color: white;
}
